import React from 'react';
import { Typography, Box } from '@mui/material';

// Helper function to format keys
const formatKey = (key) => {
  return key
    .replace(/[_-]/g, ' ') // Replace underscores and hyphens with spaces
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
};

// Recursive function to render content
const renderContent = (data, textColor, level = 0) => {
  if (Array.isArray(data)) {
    return (
      <ol style={{ paddingLeft: '16px', listStyleType: 'decimal', color: textColor }}>
        {data.map((item, index) => (
          <li key={index}>
            {typeof item === 'object' ? renderContent(item, textColor, level + 1) : (
              <Typography variant="body2" sx={{ color: textColor }}>
                {item}
              </Typography>
            )}
          </li>
        ))}
      </ol>
    );
  }

  if (typeof data === 'object' && data !== null) {
    return Object.keys(data).map((key) => {
      const value = data[key];
      return (
        <Box key={key} sx={{ mb: 2 }}>
          <Typography variant="h6" fontWeight="bold" sx={{ color: textColor }}>
            {formatKey(key)}
          </Typography>
          {renderContent(value, textColor)}
        </Box>
      );
    });
  }

  return (
    <Typography variant="body2" sx={{ color: textColor }}>
      {data}
    </Typography>
  );
};

// Component to display SDR data
function SdrDataDisplay({ data, textColor }) {
  console.log("Received data:", data); // Debugging line

  if (!data || typeof data !== 'object') {
    return (
      <Typography variant="body2" sx={{ color: textColor }}>
        No data available or invalid data format.
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {renderContent(data, textColor)}
    </Box>
  );
}

export default SdrDataDisplay;
