import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "../../assets/images/smart-assets/smart-assets-logo.svg";
import { Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

// Modify SmartProspectLayout to accept dynamic sections (e.g., LinkedIn Link input field)
function SmartProspectLayout({
                               color,
                               titleFontSize,
                               backgroundColor,
                               children,
                               customHeader, // New prop to pass custom section like LinkedIn input
                             }) {
  return (
    <PageLayout background={backgroundColor}>
      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <img src={logo} alt="Logo" style={{ height: '50px' }} />
        <VuiBox sx={{ flexGrow: 1, marginLeft: '20px' }}>
          {customHeader} {/* Render the custom section */}
        </VuiBox>
      </VuiBox>

      {/* Separator Line */}
      <Divider sx={{ bgcolor: '#fff', height: '2px' }} />

      <VuiBox
        height="100%"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        sx={{
          backgroundColor: backgroundColor || "#fff",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <VuiBox
          width="100%"
          pl={3}
          pr={3}
          sx={{
            color: color || "black",
            fontSize: titleFontSize || "1rem",
          }}
        >
          {children}
        </VuiBox>
      </VuiBox>
    </PageLayout>
  );
}

// Setting default values for the props of SmartProspectLayout
SmartProspectLayout.defaultProps = {
  color: "black",
  backgroundColor: "#fff",
  titleFontSize: "1rem",
};

// Typechecking props for SmartProspectLayout
SmartProspectLayout.propTypes = {
  color: PropTypes.string,
  titleFontSize: PropTypes.string,
  backgroundColor: PropTypes.string,
  customHeader: PropTypes.node, // Define customHeader as a node
  children: PropTypes.node.isRequired,
};

export default SmartProspectLayout;
