import { forwardRef } from "react";
import PropTypes from "prop-types";
import VuiTypographyRoot from "components/VuiTypography/VuiTypographyRoot";

const VuiTypography = forwardRef(
  (
    {
      color,
      fontWeight,
      textTransform,
      verticalAlign,
      fontSize,
      textGradient,
      opacity,
      children,
      ...rest
    },
    ref
  ) => {
    // Check if the color is a pre-defined value or a custom color (hex, rgb, etc.)
    const isPredefinedColor = [
      "inherit",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
      "white",
      "logo",
    ].includes(color);

    return (
      <VuiTypographyRoot
        {...rest}
        ref={ref}
        ownerState={{
          color: color, // Pass the color as-is, custom or predefined
          textTransform,
          verticalAlign,
          fontSize,
          fontWeight,
          opacity,
          textGradient,
        }}
      >
        {children}
      </VuiTypographyRoot>
    );
  }
);

// Setting default values for the props of VuiTypography
VuiTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  fontSize: "16px",
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

// Typechecking props for the VuiTypography
VuiTypography.propTypes = {
  color: PropTypes.string,  // Now allows any valid string for custom color
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node.isRequired,
  opacity: PropTypes.number,
};

export default VuiTypography;
