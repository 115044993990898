import React from 'react';
import { Button, Grid, Typography, Divider } from '@mui/material';
import VuiBox from 'components/VuiBox'; // Assuming VuiBox is a custom component in your project
import logo from 'assets/images/smart-assets/smart-assets-logo.svg'; // Replace with your logo path
import image from 'assets/images/smart-assets/your-prospect.png'; // Replace with your image path
import powerSalesImage from 'assets/images/smart-assets/power-sales.png';
import getInTouchImage from 'assets/images/smart-assets/get-in-touch.png';
import howItWorksImage from 'assets/images/smart-assets/how-it-works.png';
import shopify from 'assets/images/smart-assets/shopify.png'; // Replace with your image path
import clay from 'assets/images/smart-assets/clay.png'; // Replace with your image path
import drip from 'assets/images/smart-assets/drip.png'; // Replace with your image path
import ibm from 'assets/images/smart-assets/ibm.png'; // Replace with your image path
import instacart from 'assets/images/smart-assets/instacart.png'; // Replace with your image path
import nuget from 'assets/images/smart-assets/nuget.png';
import { Link } from "react-router-dom"; // Replace with your image path
const links = {
  Basic: '/smart-prospect-basic/',
  Plus: '/smart-prospect-plus/',
  Pro: '/smart-prospect/',
  'About Us': '#',
  'Contact Us': '#',
};
function HomePage() {
  return (
    <VuiBox>
      {/* Header */}
      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="20px 40px"
        bgcolor="#5B52BC"
        color="#fff" // Set the color here so it's inherited by child elements
      >
        <img src={logo} alt="Logo" style={{ height: '50px' }} />
        <VuiBox>
          {['Basic', 'Plus', 'Pro', 'About Us', 'Contact Us'].map((text, index) => (
            <Button
              key={index}
              component={Link}
              to={links[text]}  // Use the links object to set the correct URL
              sx={{
                backgroundColor: '#fff',
                color: '#5B52BC',
                marginLeft: '20px',
                padding: '8px 16px',
                border: '1px solid #fff',
                borderRadius: '20px',
              }}
            >
              {text}
            </Button>
          ))}
        </VuiBox>
      </VuiBox>

      {/* Separator Line */}
      <Divider sx={{ bgcolor: '#fff', height: '2px' }} />

      {/* Main Section */}
      <VuiBox padding="40px" bgcolor="#5B52BC" color="#fff">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="bold" gutterBottom color="#fff">
              Your Prospect Research Done In Seconds With AI.
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: '50ch', lineHeight: '2' }}>
              SmartProspect is an AI-driven sales tool designed to empower sales professionals by automating and optimizing the research process for sales calls & meetings. The platform provides real-time insights, prospect intelligence, and conversation starters, enabling sales teams to close deals more efficiently.
            </Typography>
            <VuiBox mt={5}>
              <input
                type="text"
                placeholder="Your email"
                style={{
                  padding: '10px',
                  width: '60%',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginRight: '10px',
                }}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#339933',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#2e8b2e',
                  },
                }}
              >
                Free Trial
              </Button>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={image}
              alt="Illustration"
              style={{
                width: '100%',
                height: '600px', // Maintain aspect ratio
                borderRadius: '10px',
                objectFit: 'contain',
              }}
            />
          </Grid>
        </Grid>
      </VuiBox>

      {/* Company Logos Section */}
      <VuiBox padding="40px" textAlign="center" bgcolor="#fff" color="#5B52BC">
        <Typography variant="h3" fontWeight="bold" gutterBottom mb={5} color="#fff">
          Trusted by Leading Companies
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {/* Replace these with your actual logos */}
          {[shopify, clay, ibm, instacart, drip, nuget].map((logo, index) => (
            <Grid item key={index}>
              <img src={logo} alt={`Company ${index}`} style={{ height: '50px', margin: '0 30px' }} />
            </Grid>
          ))}
        </Grid>
      </VuiBox>

      {/* Full-Width Image Section */}
      <VuiBox textAlign="center" bgcolor="#fff !important" padding="40px">
        <Typography justifyContent="center" variant="h3" fontWeight="bold" gutterBottom mb={5}>
          How it Works
        </Typography>
        <img
          src={howItWorksImage}
          alt="Full-width Image"
          style={{
            width: '100%',
            maxHeight: '700px',
            objectFit: 'contain',
            display: 'block',
          }}
        />
      </VuiBox>

      {/* Small Image with Text on Right Section */}
      <VuiBox padding="40px" bgcolor="#fff !important" color="#5B52BC">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <img
              src={powerSalesImage}
              alt="Small Image"
              style={{
                width: '100%',
                height: '700px', // Maintain aspect ratio
                borderRadius: '10px',
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              Power your sales team with the ultimate prospecting tool
            </Typography>
            <Typography variant="body1" mb={3}>
              Enable your sales team to get the best research on their prospect instantly.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#339933',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#2e8b2e',
                },
              }}
            >
              Explore
            </Button>
          </Grid>
        </Grid>
      </VuiBox>

      {/* Text in Center with Image on Right Section */}
      <VuiBox padding="40px" bgcolor="#f5f5f5" color="#5B52BC">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="bold" gutterBottom color="#fff">
              Get in touch with us
            </Typography>
            <Typography variant="body1" color="#fff" mb={3}>
              Put the customer at the center of your flywheel and contact us today to find out more.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#339933',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#2e8b2e',
                },
              }}
            >
              Get Demo
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={getInTouchImage}
              alt="Image on Right"
              style={{
                width: '100%',
                height: '700px', // Maintain aspect ratio
                borderRadius: '10px',
                objectFit: 'contain',
              }}
            />
          </Grid>
        </Grid>
      </VuiBox>

      {/* Footer */}
      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="20px 40px"
        color="#fff"
        bgcolor="#fff !important"
      >
        <img src={logo} alt="Logo" style={{ height: '50px' }} />
        <VuiBox>
          {['Privacy Policy', 'Terms of Service', 'Contact'].map((text, index) => (
            <Button
              key={index}
              sx={{
                color: 'inherit',
                marginLeft: '20px',
                padding: '8px 16px',
                border: '1px solid #fff',
                borderRadius: '20px',
                '&:hover': {
                  backgroundColor: '#fff',
                  color: '#5B52BC',
                },
              }}
            >
              {text}
            </Button>
          ))}
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

export default HomePage;
