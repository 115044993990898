import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Utility function to format keys
const formatKey = (key) => {
  return key
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

// Utility function to truncate text after two full stops
const truncateText = (text) => {
  const stopIndices = [];
  let index = text.indexOf(".");
  while (index !== -1 && stopIndices.length < 2) {
    stopIndices.push(index);
    index = text.indexOf(".", index + 1);
  }

  if (stopIndices.length >= 2) {
    // If two full stops found, truncate at the second one
    return {
      truncated: text.slice(0, stopIndices[1] + 1),
      fullText: text,
    };
  }

  // If fewer than two full stops, don't truncate
  return { truncated: text, fullText: text };
};

const TruncatedText = ({ text }) => {
  const { truncated, fullText } = truncateText(text);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <VuiTypography variant="body2" color="white">
        {isExpanded ? fullText : truncated}
        {fullText !== truncated && (
          <Button color="primary" onClick={toggleText}>
            {isExpanded ? "Show Less" : "...See More"}
          </Button>
        )}
      </VuiTypography>
    </div>
  );
};

export const RenderProfileData = ({ data }) => {
  if (!data) return null;

  return Object.entries(data).map(([key, value]) => (
    <VuiBox mb={2} key={key}>
      {typeof value === "object" && value !== null ? (
        <>
          <VuiTypography variant="h6" fontWeight="bold" gutterBottom color="white">
            {formatKey(key)}
          </VuiTypography>
          {Array.isArray(value) ? (
            value.map((item, index) =>
              typeof item === "object" ? (
                <RenderProfileData key={index} data={item} />
              ) : (
                <TruncatedText key={index} text={item} />
              )
            )
          ) : (
            <RenderProfileData data={value} />
          )}
        </>
      ) : (
        <>
          <VuiTypography variant="body1" fontWeight="bold" color="white">
            {formatKey(key)}:
          </VuiTypography>
          <TruncatedText text={value} />
        </>
      )}
    </VuiBox>
  ));
};
