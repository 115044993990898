import React, { useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import SmartProspectLayout from "../SmartProspectLayout";
import RenderProfileData from "./RenderProfileData";
import { Typography, TextField } from '@mui/material';
import borders from "../../assets/theme/base/borders";
import radialGradient from "../../assets/theme/functions/radialGradient";
import palette from "../../assets/theme/base/colors";
import GradientBorder from "examples/GradientBorder";
import FlashingIcon from 'components/FlashingIcon'; // Import the reusable component
import { useLocation } from "react-router-dom";
import CustomModal from "components/VuiModal/CustomModal";
import {BASE_URL} from  "../../constants";

function SmartProspectProfile() {
  const [link, setLink] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [linkedinData, setLinkedinData] = useState(null);
  const [sdrData, setSDRData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#5b52bc"); // Valid hex color
  const [textColor, setTextColor] = useState("#ffd427"); // Valid hex color
  const [buttonColor, setButtonColor] = useState("#ffd427"); // Valid hex color
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  // Handlers to open and close the modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const currentPath = location.pathname.split("/").filter(Boolean).pop();
  const apiUrl = `${BASE_URL}api/${currentPath}/`;
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setProfileData(null);
    setLinkedinData(null);
    setSDRData(null);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);

    try {
      const response = await axios.post(apiUrl, { profile_url: link });
      setProfileData(response.data.profile_data);
      setLinkedinData(response.data.linkedin_data);
      setSDRData(response.data.sdr_data);
    } catch (err) {
      setError("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };

  // LinkedIn input section that will be passed to the layout
  const linkedInInputSection = (
    <VuiBox>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1} />
        <Grid item xs={6}>
          <VuiTypography component="label" variant="button" color={textColor} fontWeight="medium">
            LinkedIn Link
          </VuiTypography>
          <VuiInput
            type="url"
            label="LinkedIn link"
            placeholder="LinkedIn link"
            sx={{ backgroundColor: "#ffff !important", color:`${textColor} !important`, }}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="gradient"
            sx={{
              marginTop: "30px",
              backgroundColor: buttonColor,
              color: "#fff",
              "&:hover": {
                backgroundColor: buttonColor, // Keep the same color on hover
              },
            }}
            onClick={handleSubmit}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Search"}
          </Button>
        </Grid>
        <Grid item xs={2}>
          {sdrData && <Button
            variant="contained"
            sx={{
              marginTop: "22px",
              backgroundColor: buttonColor,
              color: "#fff",
              "&:hover": {
                backgroundColor: buttonColor, // Keep the same color on hover
              },
            }}
            onClick={handleOpen}>
            SDR Call Template
          </Button>}
        </Grid>
      </Grid>
    </VuiBox>
  );

  return (
    <SmartProspectLayout
      backgroundColor={backgroundColor}
      color={textColor}
      buttonColor={buttonColor}
      customHeader={linkedInInputSection} // Pass the LinkedIn input section here
    >
        {error && <VuiTypography color="error">{error}</VuiTypography>}
      <CustomModal
        open={open}
        handleClose={handleClose}
        data={sdrData}
        textColor={textColor}
        bgColor={backgroundColor} // Pass the custom background color
      />
        {profileData && (
            <VuiBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Avatar
                    src={linkedinData?.profile_pic_url}
                    alt={linkedinData?.full_name}
                    sx={{ width: 120, height: 120, marginBottom: 2 }}
                  />
                  <VuiTypography variant="h3" fontWeight="bold" gutterBottom color={textColor}>
                    {linkedinData?.full_name}
                  </VuiTypography>
                  <VuiTypography variant="body1" color={textColor}>
                    {linkedinData?.country_full_name}
                  </VuiTypography>
                  <VuiTypography variant="body1" color={textColor}>
                    {linkedinData?.summary}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <RenderProfileData data={profileData} textColor={textColor} />
                </Grid>
              </Grid>

            </VuiBox>
        )}
    </SmartProspectLayout>
  );
}

export default SmartProspectProfile;
