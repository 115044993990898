// @mui material components
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export default styled(Typography)(({ theme, ownerState }) => {
  const { palette, typography, functions } = theme;
  const { color, textTransform, verticalAlign, fontWeight, opacity, textGradient } = ownerState;

  const { gradients, transparent } = palette;
  const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = typography;
  const { linearGradient } = functions;

  // fontWeight styles
  const fontWeights = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  // Function to check if color is a valid CSS color (hex, rgb, etc.)
  const isCustomColor = (color) => {
    // Basic regex to test for hex color codes or rgb/rgba strings
    const hexPattern = /^#([0-9A-F]{3}){1,2}$/i;
    const rgbPattern = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/i;
    const rgbaPattern = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d?\.?\d+)\)$/i;
    return hexPattern.test(color) || rgbPattern.test(color) || rgbaPattern.test(color);
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state, gradients[color].deg)
        : linearGradient(gradients.primary.main, gradients.primary.state),
    display: "inline-block",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: transparent.main,
    position: "relative",
    zIndex: 1,
  });

  // Determine if the color is a custom color or from the palette
  const finalColor = isCustomColor(color)
    ? color // Use directly if custom color (hex, rgb, rgba)
    : palette[color]?.main || palette.text.primary; // Fallback to theme or default text color

  return {
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: "none",
    color: !textGradient ? finalColor : undefined, // Apply color only if no gradient
    fontWeight: fontWeights[fontWeight] || fontWeights.regular,
    ...(textGradient && gradientStyles()), // Apply gradient styles if textGradient is true
  };
});
