import React from "react";
import { Typography, Button } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTube from "react-youtube";  // For embedding YouTube videos

// Utility function to format keys
const formatKey = (key) => {
  return key
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());  // Capitalize first letter of each word
};

// Component to render social media links
const SocialMediaLink = ({ platform, url }) => {
  let icon;
  switch (platform.toLowerCase()) {
    case "linkedin":
      icon = <LinkedInIcon />;
      break;
    case "instagram":
      icon = <InstagramIcon />;
      break;
    default:
      icon = null;
  }

  return (
    <Button
      variant="contained"
      startIcon={icon}
      href={url}
      target="_blank"
      sx={{
        marginBottom: '10px',
        marginRight: '10px',
        backgroundColor: '#5B52BC',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#4A42A6',
        }
      }}
    >
      {formatKey(platform)}
    </Button>
  );
};

// Recursive component to render YouTube video, links, and other data
export const RenderProfileData = ({ data }) => {
  if (!data) return null;

  return Object.entries(data).map(([key, value]) => (
    <VuiBox mb={2} key={key}>
      {typeof value === "object" && value !== null ? (
        <>
          <VuiTypography variant="h6" fontWeight="bold" gutterBottom color="white">
            {formatKey(key)}
          </VuiTypography>
          {Array.isArray(value) ? (
            value.map((item, index) => (
              typeof item === "object" ? (
                <RenderProfileData key={index} data={item} />
              ) : (
                key.toLowerCase().includes("youtube") ? (
                  // Handle YouTube links
                  <YouTube
                    key={index}
                    videoId={item.split("v=")[1]}
                    opts={{
                      width: '100%',
                      playerVars: { autoplay: 0 },
                    }}
                  />
                ) : (
                  <VuiTypography key={index} variant="body2" color="white">
                    {item}
                  </VuiTypography>
                )
              )
            ))
          ) : (
            <RenderProfileData data={value} />
          )}
        </>
      ) : (
        <>
          {key.toLowerCase() === "linkedin" || key.toLowerCase() === "instagram" ? (
            <SocialMediaLink platform={key} url={value} />
          ) : key.toLowerCase().includes("youtube") ? (
            <YouTube
              videoId={value.split("v=")[1]}
              opts={{
                width: '100%',
                playerVars: { autoplay: 0 },
              }}
            />
          ) : (
            <>
              <VuiTypography variant="body1" fontWeight="bold" color="white">
                {formatKey(key)}:
              </VuiTypography>
              <VuiTypography variant="body2" color="white">
                {value}
              </VuiTypography>
            </>
          )}
        </>
      )}
    </VuiBox>
  ));
};

export default RenderProfileData;
