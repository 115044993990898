import axios from "axios";
import VuiInput from "../../components/VuiInput";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import React, { useState } from "react";
import {
  Card,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Avatar,
} from "@mui/material";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { RenderProfileData } from "./RenderProfileData"; // Adjust path as necessary
import {BASE_URL} from  "../../constants";

function UserFederatedData() {
  const [link, setLink] = useState(""); // State for LinkedIn link
  const [profileData, setProfileData] = useState(null);
  const [linkedinData, setLinkedinData] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling
  const cleanJsonString = (jsonString) => {
    // Remove any characters before the opening brace
    const cleanedString = jsonString.trim().replace(/^.*?({.*})$/, '$1');
    try {
      return JSON.parse(cleanedString);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return null;
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setProfileData(null);
    setLinkedinData(null);

    try {
      const response = await axios.post(`${BASE_URL}api/get-sales-questions-through-pycurl/`, { profile_url: link });
      const data = response.data.profile_data;
      // const data = [];
      // debugger;
      // const cleanedData = cleanJsonString(data);

      setProfileData(data); // Store the parsed data
      setLinkedinData(response.data.linkedin_data); // Store the parsed data
    } catch (err) {
      setError("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <DashboardLayout>
      {/*<DashboardNavbar />*/}

      <VuiBox py={3}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              LinkedIn Link
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="url"
              placeholder="LinkedIn link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              fontWeight="500"
              fullWidth
            />
          </GradientBorder>
        </VuiBox>

        <Button
          variant="contained"
          sx={{
            backgroundColor: '#339933',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#2e8b2e',
            },
          }}
          onClick={handleSubmit}
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} color="white" /> : "Fetch Profile Data"}
        </Button>
      </VuiBox>

      {/* Display the fetched profile data */}
      {error && <VuiTypography color="error">{error}</VuiTypography>}
      {profileData && (
        <Card>
          <VuiBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Avatar
                  src={linkedinData?.profile_pic_url}
                  alt={linkedinData?.full_name}
                  sx={{ width: 120, height: 120, marginBottom: 2 }}
                />
                <VuiTypography variant="h3" fontWeight="bold" gutterBottom color="white">
                  {linkedinData?.full_name}
                </VuiTypography>
                <VuiTypography variant="body1" color="white">
                  {linkedinData?.country_full_name}
                </VuiTypography>
                <VuiTypography variant="body1" color="white">
                  {linkedinData?.summary}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} md={8}>
                <RenderProfileData data={profileData} />
              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      )}
    </DashboardLayout>
  );
}

export default UserFederatedData;
