import axios from "axios";
import VuiInput from "../../components/VuiInput";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import React, { useState } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  Avatar,
} from "@mui/material";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {BASE_URL} from  "../../constants";
function LinkedInForm() {
  const [link, setLink] = useState(""); // State for LinkedIn link
  const [profileData, setProfileData] = useState(null); // State for the fetched profile data
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${BASE_URL}api//linkedin-profile/`, { link });
      console.log("response", response);
      console.log("response", response.data);
      console.log("response", response.data.profile_data);
      setProfileData(response.data.profile_data); // Store the fetched data
    } catch (err) {
      setError("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={3}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              LinkedIn Link
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="url"
              placeholder="LinkedIn link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              fontWeight="500"
              fullWidth
            />
          </GradientBorder>
        </VuiBox>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Fetch Profile Data"}
        </Button>
      </VuiBox>

      {/* Display the fetched profile data */}
      {error && <VuiTypography color="error">{error}</VuiTypography>}
      {profileData && (
        <Card>
          <VuiBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Avatar
                  src={profileData?.profile_pic_url}
                  alt={profileData?.full_name}
                  sx={{ width: 120, height: 120, marginBottom: 2 }}
                />
                <VuiTypography variant="h5" fontWeight="bold" gutterBottom>
                  {profileData?.full_name}
                </VuiTypography>
                <VuiTypography variant="body1" color="textSecondary">
                  {profileData?.occupation}
                </VuiTypography>
                <VuiTypography variant="body2" color="textSecondary">
                  {profileData?.headline}
                </VuiTypography>
                <VuiTypography variant="body2" color="textSecondary">
                  {profileData?.city}, {profileData?.state}, {profileData?.country_full_name}
                </VuiTypography>
              </Grid>

              <Grid item xs={12} md={8}>
                <VuiTypography variant="h6" fontWeight="bold" gutterBottom>
                  Summary
                </VuiTypography>
                <VuiTypography variant="body2" color="textSecondary" gutterBottom>
                  {profileData?.summary}
                </VuiTypography>

                <VuiTypography variant="h6" fontWeight="bold" gutterBottom>
                  Experience
                </VuiTypography>
                {profileData.experiences.map((experience, index) => (
                  <VuiBox key={index} mb={2}>
                    <VuiTypography variant="body1" fontWeight="bold">
                      {experience?.title} at{" "}
                      <a href={experience?.company_linkedin_profile_url} target="_blank" rel="noopener noreferrer">
                        {experience?.company}
                      </a>
                    </VuiTypography>
                    <VuiTypography variant="body2" color="textSecondary">
                      {experience?.location}
                    </VuiTypography>
                  </VuiBox>
                ))}

                <VuiTypography variant="h6" fontWeight="bold" gutterBottom>
                  Education
                </VuiTypography>
                <VuiTypography variant="h6" fontWeight="bold" gutterBottom>
                  Languages
                </VuiTypography>
                <VuiTypography variant="body2" color="textSecondary">
                  {profileData?.languages.join(", ")}
                </VuiTypography>
              </Grid>
            </Grid>
          </VuiBox>
        </Card>
      )}
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

export default LinkedInForm;
