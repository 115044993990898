import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import SdrDataDisplay from "../../layouts/SmartProspectProfile/SdrDataDisplay";
// const sdr_p ={
//     "Call Template": {
//       "script": "Hello Gustav Westman, this is [your name] from [your company name]. I've been researching BrightBid Group and I'm keen to learn more about the challenges online advertisers face with managing ad spend efficiently. At [your company name], we assist people like yourself with AI-powered ad optimization, simplifying ad operations, and providing data-driven insights. Do you think this could help address the challenges BrightBid Group faces in optimizing ad revenue and enhancing transparency?"
//     },
//     "Possible Responses": {
//       "if_interested": "Proceed to schedule a demo or next steps in your sales process.",
//       "if_objecting": "Offer to send a follow-up email and set a reminder to contact them again."
//     },
//     "Gatekeeper Scenario": {
//       "script": "Hello, I'm calling for Gustav Westman. This is [your name] with [your company name].",
//       "if_asked_about_call_purpose": "I'm following up on an email I sent to Gustav Westman regarding our AI-powered ad optimization platform."
//     },
//     "Discovery Call": {
//       "questions": [
//         "What specific challenges are you facing with managing ad spend efficiently?",
//         "How have you been trying to work around these issues?",
//         "Who else in your team would need to be involved in discussions about this?",
//         "What has prevented you from finding a better solution to optimizing ad revenue?",
//         "What would your ideal solution look like?"
//       ]
//     },
//     "Referral Call": {
//       "script": "Hello Gustav Westman! [Mutual colleague's name] suggested I get in touch with you. I've been learning about BrightBid Group and I'd like to discuss our AI-powered ad optimization platform. Do you have a moment to chat?"
//     },
//     "Requesting a Connection": {
//       "script": "Hello [referrer's name], this is [your name] from [your company name]. I've been trying to reach Gustav Westman but haven't had any luck. Would you be able to introduce us via email? I'm keen to learn more about the challenges online advertisers face with managing ad spend efficiently."
//     },
//     "Follow-up Call": {
//       "script": "Hello Gustav Westman, this is [your name] from [your company name]. Did you have a chance to review the email I sent?"
//     },
//     "Promotional Call": {
//       "script": "Hello Gustav Westman, this is [your name] from [your company name]. We're currently offering a limited-time promotion on our AI-powered ad optimization platform. I'd love to help you take advantage of this opportunity."
//     },
//     "Lead Information": {
//       "company_summary": {
//         "url": "https://brightbid.com",
//         "tagline": "AI-based SaaS services for independent ad-spending",
//         "description": "BrightBid is an adtech company that provides online advertisers with the ability to manage their marketing using data and AI. Its primary SaaS service is an online tool that allows customers to manage results and independently bridge different advertising platforms. The company has a history of performance-based marketing and was previously known as Speqta. Its operations are global with headquarters in Stockholm, Sweden."
//       },
//       "recent_business_activities": [
//         "Rebranded from Speqta to BrightBid Group.",
//         "Continues to develop its AI-based ad management platform.",
//         "Expanding its global presence with a focus on innovative adtech solutions."
//       ],
//       "key_products_services": [
//         "AI-based ad management platform",
//         "SaaS service for managing ad results across platforms"
//       ],
//       "market_position": "Global presence with headquarters in Stockholm, Sweden. Ticker symbol: BRIGHT.",
//       "top_challenges_in_advertising_services": [
//         "Data Privacy and Regulation",
//         "Ad Fraud and Transparency",
//         "Changing Consumer Behavior and Ad Avoidance"
//       ],
//       "financial_performance_growth_trends": [
//         "Reported revenue of SEK 143.6 million for Q2 2022.",
//         "Gross margin of 74.1% in Q2 2022.",
//         "Revenue growth with a CAGR of 25% from 2019 to 2022.",
//         "Guidance for revenue growth of 20-25% for the full year 2022."
//       ],
//       "key_decision_makers": [
//         {
//           "name": "Anders Rössel",
//           "title": "CEO",
//           "background": "Over 20 years of experience in the digital marketing and advertising industry."
//         },
//         {
//           "name": "Johan Åhlén",
//           "title": "CFO",
//           "background": "Over 15 years of experience in finance and accounting, with a focus on technology and software industries."
//         },
//         {
//           "name": "Mattias Spetz",
//           "title": "CPO",
//           "background": "Over 15 years of experience in product development and management in the digital marketing and advertising industry."
//         }
//       ],
//       "emerging_trends_technologies": [
//         "AI and Machine Learning",
//         "Programmatic Advertising",
//         "Data-Driven Advertising",
//         "Voice-Activated Advertising",
//         "AR and VR Advertising",
//         "Blockchain and Transparency",
//         "5G and Edge Computing",
//         "Customer Data Platforms",
//         "Influencer Marketing",
//         "Sustainability and Social Responsibility"
//       ],
//       "competitive_landscape_summary": [
//         "Digital Advertising Agencies: WPP, Omnicom, Publicis Groupe, Interpublic Group, Dentsu Aegis Network",
//         "Ad Tech Companies: Google, Facebook, Amazon, Adobe, Salesforce",
//         "Independent Ad Tech Providers: BrightBid Group, AppNexus, Rubicon Project, OpenX"
//       ],
//       "recent_regulatory_changes_and_impact": [
//         "GDPR: Increased compliance costs, changes to business models, enhanced transparency.",
//         "CCPA: Transparency, choice, control over personal data, potential fines for non-compliance.",
//         "ePR: Explicit consent for online tracking, potential fines for non-compliance.",
//         "DSA: Stricter regulations on online advertising, including transparency and accountability measures."
//       ],
//       "potential_growth_opportunities": [
//         "Expansion into new markets in Europe and Asia",
//         "Growing demand for programmatic advertising",
//         "Increasing adoption of AI and ML in advertising",
//         "Rise of e-commerce and online shopping",
//         "Growing importance of mobile advertising"
//       ],
//       "recent_competitor_innovations": [
//         "AppNexus launches 'AppNexus Publisher Suite' and 'AppNexus AI'",
//         "Rubicon Project launches 'Rubicon Project Demand Manager' and 'Rubicon Project Supply Manager'",
//         "OpenX launches 'OpenX Ad Server' and 'OpenX AI'",
//         "Adobe Advertising Cloud launches 'Adobe Advertising Cloud DSP' and 'Adobe Advertising Cloud SSP'",
//         "Google launches 'Google Ad Manager' and 'Google Ads AI'"
//       ],
//       "common_customer_objections": [
//         "Cost and ROI concerns",
//         "Complexity and Integration issues",
//         "Data Security and Privacy concerns",
//         "Lack of Transparency and Control",
//         "Fear of Disruption"
//       ],
//       "key_value_propositions_for_brightbid": [
//         "Maximize ad revenue with AI-powered optimization",
//         "Simplify ad operations with a single, integrated platform",
//         "Gain data-driven insights with advanced analytics and reporting"
//       ],
//       "how_brightbid_addresses_challenges": [
//         "AI-powered ad optimization for revenue maximization",
//         "Integrated solution for simplifying ad operations",
//         "Advanced analytics for data-driven decision-making",
//         "Fraud detection algorithms for ad quality",
//         "User-friendly interface to bridge talent and skills gap"
//       ],
//       "recent_news_about_brightbid": [
//         "Launch of AI-powered ad management platform",
//         "Partnership with leading ad exchange to expand reach",
//         "Release of new features to enhance ad operations",
//         "Expansion into new markets with AI-powered platform"
//       ],
//       "areas_of_alignment_with_industry_benefits": [
//         "Maximize Ad Revenue: Aligned with Ad Management, Ad Exchange, SSP, DSP, Programmatic Advertising, RTB",
//         "Simplify Ad Operations: Aligned with Ad Management, Ad Serving, Ad Exchange, SSP, DSP, DMP",
//         "Enhance Ad Quality: Aligned with AI & ML for Advertising, Programmatic Advertising, RTB"
//       ],
//       "gustav_westman_profile": {
//         "name": "Gustav Westman",
//         "title": "CEO and Founder",
//         "background": "Over 15 years of experience in digital advertising. Held leadership positions at Adform, AOL, and Microsoft.",
//         "recent_public_statements": [
//           "The future of digital advertising is AI-powered, and we're committed to helping our customers stay ahead of the curve.",
//           "Transparency and accountability are essential for building trust in the digital advertising industry. We're committed to providing our customers with the tools and insights they need to make informed decisions."
//         ]
//       },
//       "meeting_talking_points": [
//         "Discuss AI-powered ad optimization and its benefits for BrightBid Group.",
//         "Explore the evolution and impact of programmatic advertising and real-time bidding.",
//         "Understand BrightBid Group's approach to data management and transparency."
//       ]
//     }
//   }

const sdr = {
  "Call Template": {
    "Introduction": "Hello Gustav Westman, this is [your name] from [your company name]. I've been researching BrightBid Group (formerly known as Speqta) and I'm keen to learn more about the challenges you may be facing in programmatic advertising, data analytics, and e-commerce solutions. At [your company name], we assist people like yourself with [value proposition 1, value proposition 2, and value proposition 3]. Do you think this could help address any challenges you might be encountering in these areas?"
  },
  "Possible Responses": {
    "If interested": "Proceed to schedule a demo or next steps in your sales process.",
      "If objecting": "Offer to send a follow-up email and set a reminder to contact them again."
  },
  "Gatekeeper Scenario": {
    "Template": "Hello, I'm calling for Gustav Westman. This is [your name] with [your company name].",
      "If asked about the call's purpose": "I'm following up on an email I sent to Gustav Westman regarding our programmatic advertising, data analytics, and e-commerce solutions."
  },
  "Discovery Call": {
    "Example Questions": [
      "What specific challenges are you facing with programmatic advertising, data analytics, and e-commerce solutions?",
      "How have you been trying to work around these issues?",
      "Who else in your team would need to be involved in discussions about this?",
      "What has prevented you from finding a better solution to these challenges?",
      "What would your ideal solution look like?"
    ]
  },
  "Referral Call": {
    "Template": "Hello Gustav Westman! [Mutual colleague's name] suggested I get in touch with you. I've been learning about BrightBid Group and I'd like to discuss our programmatic advertising, data analytics, and e-commerce solutions. Do you have a moment to chat?"
  },
  "Requesting a Connection": {
    "Template": "Hello [referrer's name], this is [your name] from [your company name]. I've been trying to reach Gustav Westman but haven't had any luck. Would you be able to introduce us via email? I'm keen to learn more about the challenges they might be facing in programmatic advertising, data analytics, and e-commerce solutions."
  },
  "Follow-up Call": {
    "Template": "Hello Gustav Westman, this is [your name] from [your company name]. Did you have a chance to review the email I sent?"
  },
  "Promotional Call": {
    "Template": "Hello Gustav Westman, this is [your name] from [your company name]. We're currently offering [state promotional offer]. I'd love to help you take advantage of this limited-time opportunity."
  }
}
function CustomModal({ open, handleClose, data, bgColor, textColor }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto', // Allow scrolling if content overflows
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '80%', // Wider modal
          maxWidth: '800px', // Set a max width
          maxHeight: '90vh', // Restrict modal height to 90% of the viewport height
          bgcolor: bgColor || 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto', // Enable vertical scrolling if content is too long
        }}
      >
        {/* Close button */}
        <Button
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            fontSize: 18,
            fontWeight: 'bold',
            color: '#000',
          }}
        >
          &times;
        </Button>

        {/* Modal Title */}
        {/*<Typography id="modal-title" component="h2" sx={{ color: textColor }}>*/}
        {/*  SDR Call Template*/}
        {/*</Typography>*/}

        {/* Modal Content */}
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {/*{data || 'This is default content for your modal.'}*/}
          <SdrDataDisplay data={data} textColor={textColor} />
        </Typography>
      </Box>
    </Modal>
  );
}

export default CustomModal;
