import React, { useState } from "react";
import { Button } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const formatKey = (key) => {
  return key
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const truncateText = (text) => {
  const stopIndices = [];
  let index = text.indexOf(".");
  while (index !== -1 && stopIndices.length < 2) {
    stopIndices.push(index);
    index = text.indexOf(".", index + 1);
  }

  if (stopIndices.length >= 2) {
    return {
      truncated: text.slice(0, stopIndices[1] + 1),
      fullText: text,
    };
  }

  return { truncated: text, fullText: text };
};

const TruncatedText = ({ text, textColor }) => {
  const { truncated, fullText } = truncateText(text);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <VuiBox>
      <VuiTypography variant="body2" color={textColor}>
        {isExpanded ? fullText : truncated}
      </VuiTypography>
      <Button onClick={toggleText}
              color="success">
        {isExpanded ? "Show Less" : "Show More"}
      </Button>
    </VuiBox>
  );
};

const RenderProfileData = ({ data, textColor }) => {
  return (
    <VuiBox>
      {Object.entries(data).map(([key, value]) => (
        <VuiBox key={key} mb={2}>
          <VuiTypography variant="h3" color={textColor}>
            {formatKey(key)}
          </VuiTypography>
          {typeof value === "string" ? (
            <TruncatedText text={value} textColor={textColor} />
          ) : (
            <pre>{JSON.stringify(value, null, 2)}</pre>
          )}
        </VuiBox>
      ))}
    </VuiBox>
  );
};

export default RenderProfileData;
